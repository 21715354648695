import React from 'react'
import Main from '../../layouts/Main'
import {
  SectionBookingForm,
  SectionSlider,
  Video,
  Notifications,
  WeatherUpdateData
} from '../../components'
import Newsletter from '../../components/Newsletter'

const FrontPage = ({ data }) => {
  const {
    mainTitle,
    id,
    recommendTitle,
    recommendedItems,
    videoTitle,
    node_locale: nodeLocale,
    allRecommendedPage,
    numbersTitle,
    numberBlocks,
    youtubeLink
  } = data.contentfulHomepage

  return (
    <Main
      displayEyjarMask
      currentId={id}
      currentLang={nodeLocale}
      isHomepage
    >
      <section className='mt-4 container'>
        <div className='row'>
          <div className='col-md-6'>
            <h1 className='hdln--1'>{mainTitle}</h1>
          </div>
        </div>
      </section>
      <SectionBookingForm currentLang={nodeLocale} />
      <Notifications currentLang={nodeLocale} />
      <WeatherUpdateData currentLang={nodeLocale} />
      <Newsletter currentLang={nodeLocale} />

      {/* This is not ready to go live */}
      {/* <NumberBlocks
        title={numbersTitle}
        items={numberBlocks}
      /> */}

      {recommendedItems && recommendedItems.length > 0 && (
        <AllRecommended
          nodeLocale={nodeLocale}
          allPage={allRecommendedPage}
          recommendedItems={recommendedItems}
          recommendTitle={recommendTitle}
        />
      )}

      {youtubeLink &&
        <Video title={videoTitle} videoUrl={youtubeLink} />
      }
    </Main>
  )
}

const AllRecommended = props => {
  const { recommendedItems, allPage, recommendTitle, nodeLocale } = props

  const prefix = nodeLocale === 'en-US' ? '/en/' : '/'
  const url = allPage ? `${prefix}${allPage.slug}` : null

  return (
    <SectionSlider
      title={recommendTitle}
      sliders={recommendedItems}
      currentLang={nodeLocale}
      allRecommendedPageUrl={url}
      hasAllRecommendedPage={!!url}
    />
  )
}

export default FrontPage
