import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../'
import * as styles from './Newsletter.module.scss'
import axios from 'axios'
import herjolfur from '../../images/herjolfur.png'

const Newsletter = ({ currentLang }) => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [status, setStatus] = useState('idle')
  const [errorMsg, setErrorMsg] = useState('')

  // Utility function to validate email addresses
  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setStatus('sending')
    setErrorMsg('') // Clear any previous error messages

    if (!email) {
      setStatus('error')
      setErrorMsg(
        currentLang === 'is-IS'
          ? 'Netfang má ekki vera autt'
          : 'Email is required'
      )
      return
    }

    if (!isValidEmail(email)) {
      setStatus('error')
      setErrorMsg(
        currentLang === 'is-IS'
          ? 'Netfang er ekki gilt'
          : 'Email address not valid'
      )
      return
    }

    // Include the name field when posting to the API
    await axios
      .post(
        `${process.env.GATSBY_BASE_SERVER_URL}/api/v1/mailchimp/subscribe`,
        {
          email,
          name,
        }
      )
      .then(() => {
        setStatus('success')
        setEmail('')
        setName('')
      })
      .catch(() => {
        setStatus('error')
        setErrorMsg(
          currentLang === 'is-IS' ? 'Ekki tókst að skrá' : 'Subscription failed'
        )
      })
  }

  const getHeadingText = () =>
    currentLang === 'is-IS'
      ? 'Skráðu þig á póstlista Herjólfs'
      : 'Subscribe to Herjólfur newsletter'

  const getDescText = () =>
    currentLang === 'is-IS'
      ? 'Fáðu nýjustu fréttir og upplýsingar beint í inboxið'
      : 'Get the latest news and information delivered to your inbox'

  const getEmailText = () => (currentLang === 'is-IS' ? 'Netfang' : 'Email')

  const getNameText = () => (currentLang === 'is-IS' ? 'Nafn' : 'Name')

  const getSubscribeText = () =>
    currentLang === 'is-IS' ? 'Skrá mig á póstlista' : 'Subscribe'

  return (
    <div className='container mt-4'>
      <div className={styles.container}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formContent}>
            <div>
              <h2>{getHeadingText()}</h2>
              <p>{getDescText()}</p>
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.inputContainer}>
                <label htmlFor="mce-EMAIL" className={styles.label}>
                  {getEmailText()}
                </label>
                <input
                  type="text"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className={styles.input}
                  id="mce-EMAIL"
                  required
                />
              </div>
              <div className={styles.inputContainer}>
                <label htmlFor="mce-NAME" className={styles.label}>
                  {getNameText()}
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  className={styles.input}
                  id="mce-NAME"
                  required
                />
              </div>
            </div>
            <div className={styles.responses}>
              {status === 'error' && (
                <div className={styles.errorResponse}>
                  {errorMsg ||
                    (currentLang === 'is-IS'
                      ? 'Ekki tókst að skrá'
                      : 'Subscription failed')}
                </div>
              )}
              {status === 'success' && (
                <div className={styles.successResponse}>
                  {currentLang === 'is-IS'
                    ? 'Áskrift staðfest'
                    : 'Successfully subscribed'}
                </div>
              )}
            </div>
            <div className={styles.submitWrapper}>
              <Button
                type="submit"
                name="subscribe"
                disabled={status === 'sending'}
              >
                {status === 'sending'
                  ? currentLang === 'is-IS'
                    ? 'Að senda...'
                    : 'Sending...'
                  : getSubscribeText()}
              </Button>
            </div>
          </div>
        </form>
        <div className={styles.featureImage}>
          <img src={herjolfur} alt="Herjolfur" />
        </div>
      </div>
    </div>
  )
}

Newsletter.propTypes = {
  currentLang: PropTypes.string.isRequired,
}

export default Newsletter
